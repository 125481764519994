import request from '@/utils/request'

/**
 * 获取商家聊天分组
 */
export function getStoreChatGroup(params) {
    return request({
        url: '/chat/storeGroup',
        method: 'get',
        params:params,
        isLogin: true,
    })
}


/**
 * 获取商家聊天记录
 */
export function getStoreMessage(params) {
    return request({
        url: '/chat/storeMessage',
        method: 'get',
        params:params,
        isLogin: true,
    })
}