<template>
    <div class="chat">
        <div class="navbar">
            <div class="back" @click="back()"><van-icon name="arrow-left" /></div>
            <div class="nickname">{{nickname}}</div>
        </div>
        <div class="list cu-chat" id="messageWrap">
            <van-pull-refresh style="height: 100%;overflow-y: scroll" pulling-text="下拉加载" loosing-text="释放加载" v-model="isLoading" @refresh="getMessageList()">
                <template v-for="(item,index) in list">
                    <div class="cu-item self" v-if="item.from_type == 1">
                        <div class="main">
                            <div class="content bg-green shadow">
                                <!-- 处理文字-->
                                <span v-if="item.content_type == 0">{{item.content}}</span>
                            </div>
                        </div>
                        <div class="cu-avatar">
                            <img :src="imageUrl + item.image" onerror="this.src='https://img01.yzcdn.cn/vant/cat.jpeg'">
                        </div>
                        <div class="date">{{item.date}}</div>
                    </div>
                    <div class="cu-item" v-if="item.from_type == 0">
                        <div class="cu-avatar">
                            <img :src="imageUrl + item.avatar" onerror="this.src='https://img01.yzcdn.cn/vant/cat.jpeg'">
                        </div>
                        <div class="main">
                            <div class="content shadow">
                                <span v-if="item.content_type == 0">{{item.content}}</span>
                            </div>
                        </div>
                        <div class="date">{{item.date}}</div>
                    </div>
                </template>
            </van-pull-refresh>
        </div>
        <div class="options">
            <van-field
                    v-model="message"
                    center
                    clearable
                    placeholder="请输入消息内容"
            >
                <template #button>
                    <van-button size="small" type="primary" @click="send()">发送</van-button>
                </template>
            </van-field>
        </div>
    </div>
</template>

<script>
    import { getToken } from '@/utils/token'
    import { getStoreMessage } from '@/api/chat'
    import { info } from '@/api/user'
	import http from '../../utils/config.js'
    export default {
        name: "ChatPage",
        data(){
            return {
                isLoading: false,
                id:0,//群组ID
                sid:0,
                nickname:"",//群组ID
                message:"",//消息
                fid:0,//最初消息记录ID
                list:[],//消息列表
                user:{},
                server:'wss://server.esw235.cn/wss',
                ws:null,
				imageUrl: http.imageUrl,
            };
        },
        created() {
            var _this = this;
            //初始化websocket
            this.ws = new WebSocket(this.server)
            this.ws.onopen = () => {
                // Web Socket 已连接上，使用 send() 方法发送数据
                this.ws.send(JSON.stringify({
                    'type': 'login',
                    'platform': 's',
                    'sid': this.sid,
                    'data': getToken()
                }));
                //心跳检测
                setInterval(() => {
                    this.ws.send(JSON.stringify({
                        'type': 'ping',
                        'data': {}
                    }))
                },5000);
                console.log('数据发送中...')
            }
            //接收消息处理
            this.ws.onmessage = res => {
                console.log('接受到数据')
                let data = JSON.parse(res.data);
                if(data.type == 'message'){
                    if(data.data.gid == this.id){ //如果是当前群组的消息
                        _this.list.push(data.data)
                    }
                }
            }
            this.ws.onclose = function () {
                // 关闭 websocket
                console.log('连接已关闭...')
            }
            // 组件销毁时调用，中断websocket链接
            this.over = () => {
                this.ws.close()
            }

            this.scrollToBottom();
        },
        mounted() {
            if(this.$route.query.hasOwnProperty('id')){
                this.id = this.$route.query.id;
            }
            if(this.$route.query.hasOwnProperty('nickname')){
                this.nickname = this.$route.query.nickname;
            }
            if(this.$route.query.hasOwnProperty('sid')){
                this.sid = this.$route.query.sid;
            }
            info().then((res) => {
                // console.log(res)
                this.user = res.data.info
            })
            //初始化聊天记录
            this.getMessageList();
        },
        methods:{
            getMessageList(){
                //获取聊天记录
                getStoreMessage({
                    id:this.id,
                    fid:this.fid
                }).then((res) => {
                    if(this.fid == 0){
                        this.list = res.data.list;
                    }else{
                        this.list.unshift(res.data.list)
                    }
                    this.fid = res.data.fid;
                    this.isLoading = false;
                });
            },
            scrollToBottom() {
                this.$nextTick(function(){
                    var div = document.getElementById('messageWrap');
                    div.scrollTop = div.scrollHeight;
                })
            },
            send(){
                this.ws.send(JSON.stringify({
                    type: 'message',
                    platform: 's', //平台
                    data: {
                        gid: this.id, //群组ID
                        content_type:0, //内容类型
                        content:this.message //内容详情
                    }
                }));

                let ms = {
                    gid:this.id,
                    from_type:1,
                    content_type:0,
                    content:this.message,
                    date:"2021年04月27日 13:43",
                    uid:5,
                    sid:3,
                    avatar:"",
                    image:this.user.image,
                }
                this.list.push(ms)
                this.message = "";
                this.scrollToBottom
            },
            back(){
                this.$router.back();
            },
        }
    }
</script>

<style scoped lang="scss">
    .bg-green {
        background-color: #39b54a!important;
        color: #ffffff!important;
        &.shadow{
            -webkit-box-shadow: 3px 3px 4px rgb(48 156 63 / 20%);
            box-shadow: 3px 3px 4px rgb(48 156 63 / 20%);
        }
    }

    .chat{
        height: 100vh;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        .navbar{
            flex-shrink: 0;
            padding: 5px;
           display: flex;
            align-items: center;
            .back{
                flex-shrink: 0;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
            }
            .nickname{
                width: calc(100% - 40px);
                flex-shrink: 1;
                text-align: center;
            }
        }
        .cu-chat{
            flex-grow: 1;
            overflow-y: scroll;
            background: #f1f1f1;
            display: flex;
            flex-direction: column;
            .cu-item {
                display: -webkit-box;
                display: -webkit-flex;
                display: flex;
                padding: 15px 15px 35px;
                position: relative;
                .date {
                    position: absolute;
                    font-size: 12px;
                    color: #8799a3;
                    width: calc(100% - 160px);
                    bottom: 10px;
                    left: 80px;
                }
                &.self {
                    justify-content: flex-end;
                    text-align: right;
                    >.main .content::after {
                        left: auto;
                        right: -6px;
                    }
                }
                >.main {
                    max-width: calc(100% - 130px);
                    margin: 0 20px;
                    display: flex;
                    align-items: center;
                    .content {
                        padding: 10px;
                        border-radius: 3px;
                        display: inline-flex;
                        max-width: 100%;
                        align-items: center;
                        font-size: 15px;
                        position: relative;
                        /*min-height: 30px;*/
                        line-height: 20px;
                        word-break: break-all;
                        word-wrap: break-word;
                        text-align: left;
                        background-color: #ffffff;
                        color: #333333;
                        &::before {
                            content: "";
                            top: 15px;
                            -webkit-transform: rotate(45deg);
                            transform: rotate(45deg);
                            position: absolute;
                            z-index: -1;
                            display: inline-block;
                            overflow: hidden;
                            width: 12px;
                            height: 12px;
                            left: -6px;
                            right: initial;
                            background-color: inherit;
                            -webkit-filter: blur(2px);
                            filter: blur(2px);
                            opacity: 0.3;
                        }
                        &::after {
                            content: "";
                            top: 13px;
                            -webkit-transform: rotate(45deg);
                            transform: rotate(45deg);
                            position: absolute;
                            z-index: 100;
                            display: inline-block;
                            overflow: hidden;
                            width: 12px;
                            height: 12px;
                            left: -6px;
                            right: initial;
                            background-color: inherit;
                        }
                    }
                }
                .cu-avatar {
                    width: 40px;
                    height: 40px;
                    margin: 0;
                    padding: 0;
                    background-color: #ccc;
                    img{
                        width: 40px;
                        height: 40px;
                        object-fit: cover;
                    }
                }
            }
        }
        .options{
            flex-shrink: 0;
            border-top: 1px solid #eee;
            z-index: 3;
            bottom: 0;
        }
    }
</style>
